import { Category } from "@/interface/menu/Category"
import { Extra, ExtraItem } from "@/interface/menu/Extra"
import { ExtraDependency, KitchenMenus } from "@/interface/menu/Menu"
import { CategoryProductRelation, Product } from "@/interface/menu/Product"
import { ReceiptLayout } from "@/interface/settings/printers/Receiptlayout"
import { ClientLocation } from "@/interface/user/User"
import { dataHydration } from "@/services/DataHydrationService"
import { useAPIStore } from "@/store/API"
import { SelectOption } from "@/ui-elements/input/select/SelectComponent.vue"
import { paramsSerializer } from "@/utils/api/paramsSerializer"
import * as Sentry from "@sentry/vue"
import axios from "axios"
import { defineStore } from "pinia"
import { useSettingsStore } from "@/store/Settings"
import { DEFAULT_MENU_ID, usePosMenusStore } from "@/store/PosMenus"
import { useI18n } from "vue-i18n"
import { Router } from "vue-router"

// Not all pages are suited for aggregation over all locations.
// So, only in the following pages we show that option:
const pagesWithAggregation: Array<string> = [
    "/pos",
    "/orders",
    "/archive",
    "/customers",
    "/customers/reviews",
    "/financial/turnover",
    "/kitchen-screen",
    "/pos/order/:id",
]

const pathHasAggregation = (path: string): boolean => {
    return pagesWithAggregation.reduce(
        (foundMatch: boolean, pathWithAggregation: string) => {
            if (foundMatch) {
                return foundMatch
            }

            pathWithAggregation = pathWithAggregation.replace(":id", "")
            // For example input is /pos/orders/1234
            const cleanPath = path.replaceAll(/[0-9]/g, "")

            return (
                pathWithAggregation === cleanPath ||
                pathWithAggregation + "/" === cleanPath
            )
        },
        false
    )
}

export interface MultiLocationState {
    force_reinitialization: boolean
    type: "none" | "multi_kitchen" | "multi_branch"
    access_tokens: { access_token: string; client_id: number }[]
    main_location_id: number
    locations: ClientLocation[]
    // In next two attributes the location ids are the keys
    location_settings: {
        [key: number]: {
            delivery_orders_color: string
            pickup_orders_color: string
            orders_color: string
        }
    }
    location_printers: {
        [key: number]: Array<ReceiptLayout>
    }
    selected_location_id: number
    selected_location_id_for_menu: number | null
    user_had_selected_main_location: boolean
    menus: { [key: number]: KitchenMenus }
    categories: {
        [key: number]: Map<number, Category>
    }
    products: {
        [key: number]: Map<number, Product>
    }
    productCategories: {
        [key: number]: Map<number, CategoryProductRelation[]>
    }
    extras: {
        [key: number]: Map<number, Extra>
    }
    extra_dependencies: {
        [key: number]: Map<number, ExtraDependency>
    }
    items: {
        [key: number]: Map<number, ExtraItem>
    }
}

export const multiLocationStore = defineStore("multiLocationStore", {
    state: (): MultiLocationState => <MultiLocationState>({
            force_reinitialization: false,
            type: localStorage.getItem("multi_location_type") ?? "none",
            access_tokens: [],
            main_location_id: localStorage.getItem("multi_main_location_id")
                ? parseInt(localStorage.getItem("multi_main_location_id") ?? "")
                : null,
            locations: [],
            location_settings: {},
            location_printers: {},
            selected_location_id: parseInt(
                localStorage.getItem("multi_selected_location_id") ?? "0"
            ),
            selected_location_id_for_menu: parseInt(
                localStorage.getItem("multi_selected_location_id") ??
                    localStorage.getItem("multi_main_location_id") ??
                    "0"
            ),
            user_had_selected_main_location: true,
            menus: {},
            categories: {},
            products: {},
            productCategories: {},
            extras: {},
            extra_dependencies: {},
            items: {},
        } as MultiLocationState),
    getters: {
        isActive: (state): boolean => state.type !== "none",
        location:
            (state) =>
            (locationId: number): ClientLocation | undefined =>
                state.locations.find(
                    (location: ClientLocation) =>
                        location.client_id === locationId
                ),
        printers:
            (state) =>
            (locationId: number): Array<ReceiptLayout> | undefined =>
                // @ts-ignore
                state.location_printers[locationId] ?? undefined,
        useLocationPrinters: (): boolean =>
            useSettingsStore().settings.admin_multi_custom_print === "1",
        mainLocationSelected: (state): boolean =>
            state.selected_location_id === 0,
        locationsForDropdown:
            (state) =>
            (fullPath: any): SelectOption[] => {
                const { t: translate } = useI18n()

                return (state.locations ?? []).reduce(
                    (
                        clientLocations: SelectOption[],
                        location: ClientLocation
                    ) => {
                        const label: string = location.company.includes(
                            location.city
                        )
                            ? location.company
                            : `${location.company} (${location.city})`

                        if (location.type === "branch") {
                            clientLocations.push({
                                value: location.client_id,
                                label: label,
                            })
                        } else {
                            // We need to do several things:
                            // On an 'aggregation page' the main location is added
                            // as the 'all branches' option...
                            if (pathHasAggregation(fullPath)) {
                                clientLocations.push({
                                    value: 0,
                                    label: translate("all_branches"),
                                    optGroup: true,
                                })

                                // @todo, we shouldn't manipulate the state inside a getter, do this differently
                                // ...and if this option was selected earlier,
                                // we need to select it again
                                if (state.user_had_selected_main_location) {
                                    state.selected_location_id = 0
                                }
                            } else if (state.selected_location_id === 0) {
                                // If we are not on an 'aggregation page' we
                                // need to deselect the 'all branches' option
                                state.selected_location_id =
                                    state.main_location_id
                            }

                            clientLocations.push({
                                value: location.client_id,
                                label: label,
                            })
                        }

                        return clientLocations
                    },
                    []
                )
            },
        menusForDropdown: (state): SelectOption[] => {
            // In the settings we only show the menus that belong to the location that is selected in the main
            // menu
            const { t: translate } = useI18n()

            return state.locations.reduce(
                (options: SelectOption[], location: ClientLocation) => {
                    for (const menuId in location.menus) {
                        const menu: string =
                            location.menus[menuId as unknown as 0 | 1 | 2] ?? ""

                        const label: string =
                            location.title_counter ??
                            (location.company.includes(location.city)
                                ? location.company
                                : `${location.company} (${location.city})`)

                        // If a location has just one menu, we only show the restaurant's label
                        options.push({
                            value: `${location.client_id}-${menuId}`,
                            label:
                                (Object.keys(location.menus).length > 1
                                    ? `${translate(menu)}: `
                                    : "") + label,
                        })
                    }

                    return options
                },
                []
            )
        },
        aggregateParameter:
            (state) =>
            (needValueOnly: boolean = false): string => {
                const value: string =
                    state.type !== "none" && state.selected_location_id === 0
                        ? "1"
                        : "0"

                return needValueOnly ? value : "doAggregate=" + value
            },
        addSelectionLocationToTitle:
            (state) =>
            (baseTitle: string): string => {
                // @ts-ignore
                if (!state.isActive) {
                    return baseTitle
                }
                // @ts-ignore
                const location = state.location(state.selected_location_id)

                if (!location) {
                    return baseTitle
                }

                return (
                    baseTitle +
                    " - " +
                    (location.title_counter ?? location.company)
                )
            },
        showNoAggregationPossibleNotification:
            (state) =>
            (fullPath: string): boolean => {
                return (
                    // @ts-ignore
                    state.isActive &&
                    state.user_had_selected_main_location &&
                    !pathHasAggregation(fullPath)
                )
            },
    },
    actions: {
        // All the functions called by initialize don't fetch the data again if we've already done that.
        // The assumption is that between logging on and off the location, settings and printers are not changed.
        // @todo, call this function with true if settings or printers are changed
        async initialize(force: boolean = false) {
            this.force_reinitialization = force
            await this.fetchLocations()
            await this.fetchLocationSettings()
            await this.fetchPrinters()

            this.force_reinitialization = false

            return true
        },
        // If multi location is active we need a couple settings from all locations
        async fetchLocationSettings() {
            if (
                this.isActive &&
                (Object.keys(this.location_settings).length === 0 ||
                    this.force_reinitialization)
            ) {
                const response = await axios.get(
                    "/client/multi-location-settings",
                    {
                        params: { main_location_id: this.main_location_id },
                        paramsSerializer: paramsSerializer,
                    }
                )
                this.location_settings = response.data.data
            }

            return true
        },
        async fetchPrinters() {
            if (
                this.isActive &&
                (Object.keys(this.location_printers).length === 0 ||
                    this.force_reinitialization)
            ) {
                const response = await axios.get("client/printers/selection", {
                    params: { main_location_id: this.main_location_id },
                    paramsSerializer: paramsSerializer,
                })

                response.data.data.every((printer: ReceiptLayout) => {
                    if (printer.client_id in this.location_printers) {
                        // @ts-ignore
                        this.location_printers[printer.client_id].push(printer)
                    } else {
                        // @ts-ignore
                        this.location_printers[printer.client_id] = [printer]
                    }

                    return true
                })
            }

            return true
        },
        async fetchLocations() {
            if (
                this.isActive &&
                (this.locations.length === 0 || this.force_reinitialization)
            ) {
                const response = await axios.get("client/multi-locations", {
                    params: { main_location_id: this.main_location_id },
                    paramsSerializer: paramsSerializer,
                })

                this.locations = response.data.data.locations
            }

            return true
        },
        setType(
            clientType: "none" | "multi_kitchen" | "multi_branch",
            clientId: number
        ) {
            // Once the type is set different from none, we don't switch it back to none
            if (clientType !== "none") {
                this.type = clientType
                localStorage.setItem("multi_location_type", this.type)
                this.main_location_id = clientId
                this.selected_location_id_for_menu = clientId
                localStorage.setItem(
                    "multi_main_location_id",
                    this.main_location_id.toString()
                )
            }
        },
        async swapLocation(locationId: number, router: Router) {
            if (locationId === this.selected_location_id) {
                return
            }

            localStorage.setItem(
                "multi_selected_location_id",
                locationId.toString()
            )
            this.selected_location_id = locationId
            this.selected_location_id_for_menu = locationId
            this.user_had_selected_main_location =
                this.selected_location_id === 0

            // Load the menu that belongs to the selected location
            this.swapMenu(
                this.selected_location_id_for_menu +
                    "-" +
                    (useSettingsStore().settings.counter_menu ??
                        DEFAULT_MENU_ID)
            )

            if (this.access_tokens.length === 0) {
                const response = await axios.get(
                    "/client/location-access-tokens",
                    {
                        params: { main_location_id: this.main_location_id },
                        paramsSerializer: paramsSerializer,
                    }
                )
                this.access_tokens = response.data.data
            }

            // Swap out access token
            const accessToken:
                | { access_token: string; client_id: number }
                | undefined = this.access_tokens.find(
                (accessToken: { access_token: string; client_id: number }) =>
                    accessToken.client_id === this.selected_location_id ||
                    (this.selected_location_id === 0 &&
                        accessToken.client_id === this.main_location_id)
            )

            if (!accessToken) {
                return true
            }

            // The logout will clear all stores
            await useAPIStore().logout(true)
            await useAPIStore().setBearerToken(accessToken.access_token)
            // Do the data hydration again
            await dataHydration.start(true)

            // Hacky way to reload just components on the current page (preventing a full reload).
            // We need to navigate away first, let's go to orders overview,
            // and from there we navigate to the page we came from
            await router.push({
                name: "orders",
                params: { navigateTo: router.currentRoute.value.path },
            })

            return true
        },
        async swapMenu(locationIdMenuId: string, forceReload: boolean = false) {
            const locationAndMenu: Array<any> = locationIdMenuId.split("-")
            this.selected_location_id_for_menu = parseInt(locationAndMenu[0])
            const menuId: number = parseInt(locationAndMenu[1])

            if (
                !Object.prototype.hasOwnProperty.call(
                    this.menus,
                    this.selected_location_id_for_menu.toString()
                ) ||
                // This is needed when categories have been moved around
                forceReload
            ) {
                try {
                    const response = await axios.get("client/pos/menu", {
                        baseURL: axios.defaults.baseURL?.replace("v1", "v2"),
                        params: {
                            main_location_id:
                                multiLocationStore().main_location_id,
                            location_id: this.selected_location_id_for_menu,
                        },
                        paramsSerializer: paramsSerializer,
                    })
                    const data = response.data.data
                    this.menus[this.selected_location_id_for_menu] = data?.menus

                    this.categories[this.selected_location_id_for_menu] =
                        data?.categories.reduce(
                            (
                                accumulator: Map<number, Category>,
                                category: Category
                            ) => {
                                accumulator.set(category.id, category)
                                return accumulator
                            },
                            new Map()
                        )

                    this.products[this.selected_location_id_for_menu] =
                        data?.products.reduce(
                            (
                                accumulator: Map<number, Product>,
                                product: Product
                            ) => {
                                accumulator.set(product.id, product)
                                return accumulator
                            },
                            new Map()
                        )

                    this.productCategories[this.selected_location_id_for_menu] =
                        Object.keys(data?.product_categories).reduce(
                            (
                                accumulator: Map<
                                    number,
                                    CategoryProductRelation[]
                                >,
                                key
                            ) => {
                                accumulator.set(
                                    Number(key),
                                    data?.product_categories[key]
                                )
                                return accumulator
                            },
                            new Map()
                        )

                    this.extras[this.selected_location_id_for_menu] =
                        data?.extras.reduce(
                            (accumulator: Map<number, Extra>, extra: Extra) => {
                                accumulator.set(extra.id, extra)
                                return accumulator
                            },
                            new Map()
                        )

                    this.extra_dependencies[
                        this.selected_location_id_for_menu
                    ] = data.extra_dependencies.reduce(
                        (
                            accumulator: Map<number, ExtraDependency>,
                            extraDependency: ExtraDependency
                        ) => {
                            accumulator.set(
                                extraDependency.extra_id,
                                extraDependency
                            )
                            return accumulator
                        },
                        new Map()
                    )

                    this.items[this.selected_location_id_for_menu] =
                        data?.items.reduce(
                            (
                                accumulator: Map<number, ExtraItem>,
                                item: ExtraItem
                            ) => {
                                accumulator.set(item.id, item)
                                return accumulator
                            },
                            new Map()
                        )
                } catch (error) {
                    console.error("Error fetching menu: ", error)
                    Sentry.captureException(error)

                    return false
                }
            }

            usePosMenusStore().setMenu(
                this.menus[this.selected_location_id_for_menu],
                this.categories[this.selected_location_id_for_menu],
                this.products[this.selected_location_id_for_menu],
                this.productCategories[this.selected_location_id_for_menu],
                this.extras[this.selected_location_id_for_menu],
                this.extra_dependencies[this.selected_location_id_for_menu],
                this.items[this.selected_location_id_for_menu],
                menuId
            )

            return true
        },
    },
})
